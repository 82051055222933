<template>
  <MyNavbar />
  <h2 class="text-center">Cart Page</h2>
  <div class="secPrroductInHome mt-5">
    <div class="container">
      <div class="row py-2">
        <div
          :class="{ 'col-md-3': !isFiltered, 'col-md-6': isFiltered }"
          v-for="product in paginatedProducts"
          :key="product.id"
        >
          <div class="DivProduct border">
            <div class="productImage">
              <img :src="product.photo || 'placeholder.jpg'" alt="notFound" />
            </div>
            <div class="contentProduct">
              <p>{{ product.name || "اسم غير متوفر" }}</p>
              <div class="d-flex justify-content-between">
                <span class="colorOrange d-flex">
                  <span>السعر :</span>
                  <span>{{ product.purchasing_price || 0 }}</span>
                </span>
                <span class="colorOrange d-flex">
                  <span>العمولة :</span>
                  <span>{{ product.commission || 0 }}</span>
                </span>
              </div>
              <small class="pt-2 d-flex">
                <span>متوفر في المخزون :</span>
                <span class="mx-1">{{ product.total_stock || 0 }}</span>
              </small>
              <div class="d-flex justify-content-between mt-3">
                <button
                  class="btn btn-primary px-3"
                  @click="removeFromCart(product.id)"
                >
                  <font-awesome-icon
                    class="text-white"
                    :icon="['fas', 'cart-shopping']"
                  />
                  {{ cartButtonText[product.id] || "ازاله من السلة" }}
                </button>
                <button
                  class="btn border btnBorderRadius"
                  @click="toggleFavorite(product.id)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'heart']"
                    :class="{ activeHeart: isFavorite(product.id) }"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Conditional Pagination -->
        <div
          class="pagination-container text-center mt-4"
          v-if="showPagination"
        >
          <button
            class="btn btn-secondary mx-1"
            :disabled="currentPage === 1"
            @click="prevPage"
          >
            السابق
          </button>
          <span> {{ currentPage }} من {{ totalPages }} </span>
          <button
            class="btn btn-secondary mx-1"
            :disabled="currentPage === totalPages"
            @click="nextPage"
          >
            التالي
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer Component -->
  <MyFooter />
</template>

<script>
import MyNavbar from "@/components/Navbar.vue";
import MyFooter from "@/components/footer.vue";

export default {
  name: "Cart_page",
  components: {
    MyNavbar,
    MyFooter,
  },
  data() {
    return {
      products: [],
      paginatedProducts: [],
      cartButtonText: {},
      isFiltered: false,
      favorites: new Set(),
      currentPage: 1, // Tracks the current page
      pageSize: 8, // Number of products per page
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.products.length / this.pageSize); // Calculate total pages
    },
    showPagination() {
      // Show pagination only if more than 4 products exist
      return this.products.length > 4;
    },
  },
  async mounted() {
    await this.getProducts();
  },
  methods: {
    updatePagination() {
      if (this.products.length > 4) {
        const start = (this.currentPage - 1) * this.pageSize;
        const end = start + this.pageSize;
        this.paginatedProducts = this.products.slice(start, end); // Slice products for the current page
      } else {
        // Show all products if 4 or fewer
        this.paginatedProducts = [...this.products];
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.updatePagination(); // Update displayed products
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.updatePagination(); // Update displayed products
      }
    },
    async getProducts() {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          throw new Error("Authentication token is missing!");
        }

        const response = await fetch(
          "https://ecomerg.za3bot.com/api/viewProducts",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("API Response:", data);

        if (data.status === 1 && Array.isArray(data.data.products)) {
          this.products = data.data.products;
          this.paginatedProducts = [...this.products];
        } else {
          console.error("Unexpected API response format:", data);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    async removeFromCart(product_id) {
      try {
        const response = await fetch(
          `https://ecomerg.za3bot.com/api/deleteFromCart`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify({
              product_id: product_id,
              // size: "M", // Provide a default size or fetch dynamically
              // color: "Red", // <-- Provide a default color or fetch dynamically based on product
              quantity: 1, // Ensure quantity is always provided
              action: "remove",
            }),
          }
        );

        const data = await response.json();

        if (response.ok && data.status === 1) {
          this.products = this.products.filter(
            (product) => product.id !== product_id
          );
          this.paginatedProducts = [...this.products];
          console.log("Product removed from cart successfully");
        } else {
          console.error("Failed to remove product from cart:", data.msg);
        }
      } catch (error) {
        console.error("Error while removing product from cart:", error);
      }
    },
    toggleFavorite(product_id) {
      if (this.favorites.has(product_id)) {
        this.favorites.delete(product_id);
        this.updateFavoriteStatus(product_id, false);
      } else {
        this.favorites.add(product_id);
        this.updateFavoriteStatus(product_id, true);
      }
    },
    isFavorite(product_id) {
      return this.favorites.has(product_id);
    },
    async updateFavoriteStatus(product_id, isFavorited) {
      try {
        const response = await fetch(
          `https://ecomerg.za3bot.com/api/add/favorite`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
            body: JSON.stringify({
              product_id,
              action: isFavorited ? "add" : "remove",
            }),
          }
        );

        const data = await response.json();

        if (data.status !== 1) {
          console.error("Failed to update favorite status:", data.message);
        }
      } catch (error) {
        console.error("Error updating favorite status:", error);
      }
    },
  },
};
</script>

<style>
.secPrroductInHome a {
  text-decoration: none;
  font-weight: bold;
}
.DivProduct {
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0px;
}
.DivProduct .productImage {
  width: 100%;
  height: 60%;
}
.DivProduct .productImage img {
  width: 100%;
  height: 100%;
}
.contentProduct {
  padding: 5px;
}
.contentProduct p {
  color: #0c2d58;
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 18px;
}
.contentProduct span {
  font-weight: bold;
  text-align: justify;
  display: block;
  font-size: 14px;
}
.contentProduct small {
  display: block;
  color: #565656;
  text-align: justify;
}
.btnBorderRadius {
  border-radius: 50%;
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activeHeart {
  color: red;
}
font-awesome-icon {
  cursor: pointer;
  transition: color 0.3s ease;
}
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.pagination-container button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #0c2d58;
  border-color: #0c2d58;
}

.pagination-container button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
