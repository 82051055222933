<template>
  <MyNavbar />
  <h2 class="text-center">Favourate Page</h2>
  <div class="secPrroductInHome mt-5">
    <div class="container">
      <div class="row py-2">
        <div
          :class="{ 'col-md-3': !isFiltered, 'col-md-6': isFiltered }"
          v-for="product in paginatedProducts"
          :key="product.id"
        >
          <div class="DivProduct border">
            <div class="productImage">
              <img :src="product.photo || 'placeholder.jpg'" alt="notFound" />
            </div>
            <div class="contentProduct">
              <p>{{ product.name || "اسم غير متوفر" }}</p>
              <div class="d-flex justify-content-between">
                <span class="colorOrange d-flex">
                  <span>السعر :</span>
                  <span>{{ product.purchasing_price || 0 }}</span>
                </span>
                <span class="colorOrange d-flex">
                  <span>العمولة :</span>
                  <span>{{ product.commission || 0 }}</span>
                </span>
              </div>
              <small class="pt-2 d-flex">
                <span>متوفر في المخزون :</span>
                <span class="mx-1">{{ product.total_stock || 0 }}</span>
              </small>
              <div class="d-flex justify-content-between mt-3">
                <button
                  class="btn btn-primary px-3"
                  @click="toggleAddToCart(product.id)"
                >
                  <font-awesome-icon
                    class="text-white"
                    :icon="['fas', 'cart-shopping']"
                  />
                  {{ cartButtonText[product.id] || "أضف الى السلة" }}
                </button>
                <button
                  class="btn border btnBorderRadius"
                  @click="toggleFavorite(product.id)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'heart']"
                    :class="{ activeHeart: isFavorite(product.id) }"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyNavbar from "@/components/Navbar.vue";

export default {
  name: "fav_page",
  components: {
    MyNavbar,
  },
  data() {
    return {
      products: [],
      paginatedProducts: [],
      cartButtonText: {},
      isFiltered: false,
      favorites: new Set(),
    };
  },
  async mounted() {
    await this.getProducts();
  },
  methods: {
    async getProducts() {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          throw new Error("Authentication token is missing!");
        }

        const response = await fetch(
          "https://ecomerg.za3bot.com/api/viewProducts",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("API Response:", data);

        if (data.status === 1 && Array.isArray(data.data.products)) {
          this.products = data.data.products;
          this.paginatedProducts = [...this.products];
        } else {
          console.error("Unexpected API response format:", data);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    toggleAddToCart(product_id) {
      this.cartButtonText[product_id] =
        this.cartButtonText[product_id] === "أضف الى السلة"
          ? "إزالة"
          : "أضف الى السلة";
    },
    toggleFavorite(product_id) {
      if (this.favorites.has(product_id)) {
        this.favorites.delete(product_id);
        this.updateFavoriteStatus(product_id, false);
      } else {
        this.favorites.add(product_id);
        this.updateFavoriteStatus(product_id, true);
      }
    },
    isFavorite(product_id) {
      return this.favorites.has(product_id);
    },
    async updateFavoriteStatus(product_id, isFavorited) {
      try {
        const response = await fetch(
          `https://ecomerg.za3bot.com/api/add/favorite`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
            body: JSON.stringify({
              product_id,
              action: isFavorited ? "add" : "remove",
            }),
          }
        );

        const data = await response.json();

        if (data.status !== 1) {
          console.error("Failed to update favorite status:", data.message);
        }
      } catch (error) {
        console.error("Error updating favorite status:", error);
      }
    },
  },
};
</script>

<style>
.secPrroductInHome a {
  text-decoration: none;
  font-weight: bold;
}
.DivProduct {
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0px;
}
.DivProduct .productImage {
  width: 100%;
  height: 60%;
}
.DivProduct .productImage img {
  width: 100%;
  height: 100%;
}
.contentProduct {
  padding: 5px;
}
.contentProduct p {
  color: #0c2d58;
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 18px;
}
.contentProduct span {
  font-weight: bold;
  text-align: justify;
  display: block;
  font-size: 14px;
}
.contentProduct small {
  display: block;
  color: #565656;
  text-align: justify;
}
.btnBorderRadius {
  border-radius: 50%;
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activeHeart {
  color: red;
}
font-awesome-icon {
  cursor: pointer;
  transition: color 0.3s ease;
}
</style>
