<template>
  <div>
    <!-- Navbar Component -->
    <MyNavbar />

    <!-- Filters Section -->
    <div class="navbar bg-light">
      <div class="nav-item">
        <select
          v-model="category_id"
          @change="handleCategoryChange"
          class="btn btnselect custom-select"
        >
          <option value="">اختر القسم</option>
          <option
            v-for="category in categories"
            :key="category.id"
            :value="category.id"
          >
            {{ category.name }}
          </option>
        </select>
      </div>
      <div class="nav-item">
        <select
          v-model="selectedVendor"
          @change="filterProductsByVendor"
          class="btn btnselect custom-select"
        >
          <option value="" selected>اختر البائع</option>
          <option
            v-for="vendor in vendors"
            :key="vendor?.id || vendor.name"
            :value="vendor?.name"
          >
            {{ vendor?.name || "غير معروف" }}
          </option>
        </select>
      </div>
      <!-- Price Filter -->
      <div class="nav-item">
        <!-- Price Filter -->
        <select
          v-model="selectedPriceOrder"
          @change="applyPriceFilter"
          class="btn btnselect custom-select"
        >
          <option value="">فرز حسب السعر</option>
          <option value="high">الأعلى</option>
          <option value="low">الأقل</option>
        </select>
      </div>

      <!-- Commission Filter -->
      <div class="nav-item">
        <!-- Commission Filter -->
        <select
          v-model="selectedCommissionOrder"
          @change="applyCommissionFilter"
          class="btn btnselect custom-select"
        >
          <option value="">فرز حسب العمولة</option>
          <option value="high">الأعلى</option>
          <option value="low">الأقل</option>
        </select>
      </div>
      <div class="nav-item custom-selects" @click="exportToExcel">
        <span class="nav-icon">
          <font-awesome-icon :icon="['fas', 'download']" class="mx-2" />
        </span>
        تصدير
      </div>
      <div class="nav-item custom-selects" @click="toggleFilter">
        <span class="nav-icon">
          <font-awesome-icon :icon="['fas', 'table-columns']" class="mx-2" />
        </span>
        تصفية
      </div>
    </div>

    <div class="secPrroductInHome mt-5">
      <div class="container">
        <!-- Default Product List -->
        <div v-if="!category_id">
          <div class="row py-2">
            <div
              :class="{ 'col-md-3': !isFiltered, 'col-md-6': isFiltered }"
              v-for="product in paginatedProducts"
              :key="product.id"
            >
              <div class="DivProduct border">
                <div class="productImage">
                  <img
                    :src="product.photo || 'placeholder.jpg'"
                    alt="Product Image"
                  />
                </div>
                <div class="contentProduct">
                  <p>{{ product.name }}</p>
                  <div class="d-flex justify-content-between">
                    <span class="colorOrange d-flex">
                      <span>السعر :</span>
                      <span>{{ product.purchasing_price }}</span>
                    </span>
                    <span class="colorOrange d-flex">
                      <span>العمولة :</span>
                      <span>{{ product.commission }}</span>
                    </span>
                  </div>
                  <small class="pt-2">
                    <span>متوفر في المخزون :</span>
                    <span class="mx-1">{{ product.total_stock }}</span>
                  </small>
                  <div class="d-flex justify-content-between mt-3">
                    <button
                      class="btn btn-primary px-3"
                      @click="toggleAddToCart(product.id)"
                    >
                      <font-awesome-icon
                        class="text-white"
                        :icon="['fas', 'cart-shopping']"
                      />
                      {{ cartButtonText[product.id] || "أضف الى السلة" }}
                    </button>
                    <button
                      class="btn border btnBorderRadius"
                      @click="toggleFavorite(product.id)"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'heart']"
                        :class="{ activeHeart: isFavorite(product.id) }"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- No Products Found -->
          <div v-if="paginatedProducts.length === 0" class="text-center">
            <h5>لا توجد منتجات لعرضها</h5>
          </div>
        </div>

        <!-- Category-Specific Products -->
        <div v-else>
          <div class="row py-2">
            <div class="row">
              <h4 class="text-center mt-0 pt-0">Category Products</h4>
            </div>
            <div
              :class="{ 'col-md-3': !isFiltered, 'col-md-6': isFiltered }"
              v-for="product in paginatedCategoryProducts"
              :key="product.id"
            >
              <div class="DivProduct border">
                <div class="productImage">
                  <img
                    :src="product.photo || 'placeholder.jpg'"
                    alt="Product Image"
                  />
                </div>
                <div class="contentProduct">
                  <p>{{ product.name }}</p>
                  <div class="d-flex justify-content-between">
                    <span class="colorOrange d-flex">
                      <span>السعر :</span>
                      <span>{{ product.purchasing_price }}</span>
                    </span>
                    <span class="colorOrange d-flex">
                      <span>العمولة :</span>
                      <span>{{ product.commission }}</span>
                    </span>
                  </div>
                  <small class="pt-2">
                    <span>متوفر في المخزون :</span>
                    <span class="mx-1">{{ product.total_stock }}</span>
                  </small>
                  <div class="d-flex justify-content-between mt-3">
                    <button
                      class="btn btn-primary px-3"
                      @click="toggleAddToCart(product.id)"
                    >
                      <font-awesome-icon
                        class="text-white"
                        :icon="['fas', 'cart-shopping']"
                      />
                      {{ cartButtonText[product.id] || "أضف الى السلة" }}
                    </button>
                    <button
                      class="btn border btnBorderRadius"
                      @click="toggleFavorite(product.id)"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'heart']"
                        :class="{ activeHeart: isFavorite(product.id) }"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- No Category Products Found -->
          <div
            v-if="paginatedCategoryProducts.length === 0"
            class="text-center"
          >
            <p>لا توجد منتجات في هذا القسم</p>
          </div>
        </div>

        <!-- Shared Pagination Controls -->
        <div
          v-if="totalPages > 1"
          class="pagination-container m-auto w-50 text-center"
        >
          <button
            class="pagination-button btn btn-primary"
            :disabled="currentPage === 1"
            @click="prevPage"
          >
            السابق
          </button>
          <button
            v-for="page in totalPages"
            :key="page"
            :class="[
              'pagination-button btn border-primary mx-1',
              { active: currentPage === page },
            ]"
            @click="changePage(page)"
          >
            {{ page }}
          </button>
          <button
            class="pagination-button btn btn-primary"
            :disabled="currentPage === totalPages"
            @click="nextPage"
          >
            التالي
          </button>
        </div>
      </div>
    </div>

    <!-- Footer Component -->
    <MyFooter />
  </div>
</template>

<script>
import MyNavbar from "@/components/Navbar.vue";
import MyFooter from "@/components/footer.vue";
import * as XLSX from "xlsx";

export default {
  name: "Products_",
  components: {
    MyNavbar,
    MyFooter,
  },
  data() {
    return {
      products: [], // All products
      productsCat: [], // All products
      categories: [], // All categories
      category_id: "", // Currently selected category
      selectedVendor: "", // Bound to the select value
      vendors: [], // Array to hold vendors fetched from the API
      selectedPriceOrder: "", // Filter price order
      selectedCommissionOrder: "", // Filter commission order
      favoriteProducts: [], // List of favorite product IDs
      cartProducts: [], // List of cart product IDs
      cartButtonText: {}, // Cart button text for each product
      token: null, // Authentication token
      isFiltered: false, // Flag for showing filters
      currentPage: 1, // Current pagination page
      pageSize: 8, // Number of items per page
    };
  },
  computed: {
    // Filtered products for both arrays

    totalPages() {
      return this.category_id
        ? Math.ceil(this.productsCat.length / this.pageSize)
        : Math.ceil(this.products.length / this.pageSize);
    },
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.pageSize;
      return this.products.slice(start, start + this.pageSize);
    },
    paginatedCategoryProducts() {
      const start = (this.currentPage - 1) * this.pageSize;
      return this.productsCat.slice(start, start + this.pageSize);
    },
  },
  methods: {
    async fetchCategories() {
      this.ensureToken();
      try {
        const response = await fetch(
          "https://ecomerg.za3bot.com/api/categories",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        const data = await response.json();
        if (data.status === 1) {
          this.categories = data.data.categories;
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async fetchProductsByCategory(category_id) {
      if (!category_id) return;
      this.ensureToken();
      try {
        const response = await fetch(
          `https://ecomerg.za3bot.com/api/viewProducts?category=${encodeURIComponent(
            category_id
          )}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        const data = await response.json();
        if (data.status === 1) {
          this.productsCat = data.data.products;
        } else {
          console.error("Error fetching products by category:", data.msg);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    async fetchVendors() {
      this.ensureToken(); // Ensure token is set
      try {
        const response = await fetch(
          "https://ecomerg.za3bot.com/api/getVendors",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`, // Include token in the request
            },
          }
        );

        // Check for HTTP errors
        if (!response.ok) {
          throw new Error(
            `API Error: ${response.status} ${response.statusText}`
          );
        }

        const data = await response.json();

        // Handle specific unauthenticated response
        if (data.status === 0 && data.msg === "unauthenticated") {
          this.handleUnauthenticated(); // Handle unauthenticated case
          return;
        }

        // Validate the API response structure
        if (data.status === 1) {
          this.vendors = data.data.vendors;
          console.log("Vendors fetched successfully:", this.vendors);
        } else {
          console.error("Unexpected API response format or status:", data);
        }
      } catch (error) {
        console.error("Error fetching vendors:", error);
        alert("Failed to fetch vendors. Please try again later.");
      }
    },
    // Filter products and productsCat based on the selected vendor
    // Filter products and productsCat by the selected vendor
    filterProductsByVendor() {
      if (!this.selectedVendor) {
        // Reset the original product lists when no vendor is selected
        this.getProducts(); // Fetch all products again
        this.fetchProductsByCategory(this.category_id); // Fetch category-specific products again
        return;
      }

      // Re-fetch the original product lists
      this.getProducts();
      this.fetchProductsByCategory(this.category_id);

      // Apply the filter after refreshing the original data
      setTimeout(() => {
        // Filter `products` by the selected vendor
        this.products = this.products.filter(
          (product) => product.vendor === this.selectedVendor
        );

        // Filter `productsCat` by the selected vendor
        this.productsCat = this.productsCat.filter(
          (product) => product.vendor === this.selectedVendor
        );

        console.log("Filtered Products by Vendor:", this.products);
        console.log("Filtered Category Products by Vendor:", this.productsCat);
      }, 500); // Delay to ensure data is fetched before filtering
    },
    applyPriceFilter() {
      this.currentPage = 1; // Reset pagination to the first page

      // Define sorting logic for price
      const priceSortFunction = (a, b) => {
        return this.selectedPriceOrder === "high"
          ? b.purchasing_price - a.purchasing_price
          : a.purchasing_price - b.purchasing_price;
      };

      // Apply sorting to products
      this.products = [...this.products].sort(priceSortFunction);

      // Apply sorting to productsCat
      if (this.productsCat.length > 0) {
        this.productsCat = [...this.productsCat].sort(priceSortFunction);
      }
    },
    applyCommissionFilter() {
      this.currentPage = 1; // Reset pagination to the first page

      // Define sorting logic for commission
      const commissionSortFunction = (a, b) => {
        return this.selectedCommissionOrder === "high"
          ? b.commission - a.commission
          : a.commission - b.commission;
      };

      // Apply sorting to products
      this.products = [...this.products].sort(commissionSortFunction);

      // Apply sorting to productsCat
      if (this.productsCat.length > 0) {
        this.productsCat = [...this.productsCat].sort(commissionSortFunction);
      }
    },

    // Existing methods...,
    async handleCategoryChange() {
      this.selectedVendor = ""; // Clear the selected vendor
      this.currentPage = 1; // Reset to the first page
      await this.fetchProductsByCategory(this.category_id);
    },
    async toggleFavorite(product_id) {
      try {
        // Ensure the token is available
        this.ensureToken(); // Calls a helper function to check the token

        const isFavorite = this.isFavorite(product_id);
        const action = isFavorite ? "remove" : "add";

        const response = await fetch(
          "https://ecomerg.za3bot.com/api/add/favorite", // Replace this with your actual API endpoint
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`, // Attach token here
            },
            body: JSON.stringify({
              product_id,
              action,
            }),
          }
        );

        const data = await response.json();

        // Handle unauthenticated response
        if (data.status === 0 && data.msg === "unauthenticated") {
          alert("Session expired. Please log in again.");
          localStorage.removeItem("authToken"); // Clear invalid token
          window.location.href = "/login"; // Redirect to login
          return;
        }

        if (data.status === 1) {
          // Update the UI
          if (isFavorite) {
            this.favoriteProducts = this.favoriteProducts.filter(
              (id) => id !== product_id
            );
          } else {
            this.favoriteProducts.push(product_id);
          }
        } else {
          console.error(data.msg || "Failed to toggle favorite");
        }
      } catch (error) {
        console.error("Error toggling favorite:", error);
      }
    },
    isFavorite(product_id) {
      return this.favoriteProducts.includes(product_id);
    },

    async toggleAddToCart(product_id) {
      const isInCart = this.cartProducts.includes(product_id);
      try {
        const response = await fetch(
          "https://ecomerg.za3bot.com/api/addtocart",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify({
              product_id,
              quantity: 1,
              action: isInCart ? "remove" : "add",
            }),
          }
        );
        const data = await response.json();
        if (data.status === 1) {
          if (isInCart) {
            this.cartProducts = this.cartProducts.filter(
              (id) => id !== product_id
            );
            this.cartButtonText[product_id] = "أضف الى السلة";
          } else {
            this.cartProducts.push(product_id);
            this.cartButtonText[product_id] = "إزالة من السلة";
          }
        } else {
          console.error(data.msg || "Failed to toggle cart");
        }
      } catch (error) {
        console.error("Error toggling cart:", error);
      }
    },

    ensureToken() {
      this.token = localStorage.getItem("authToken");
      if (!this.token) {
        alert("Authentication token is missing. Please log in.");
        window.location.href = "/login";
      }
    },
    toggleFilter() {
      this.isFiltered = !this.isFiltered;
    },
    changePage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
        window.scrollTo(0, 0);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        window.scrollTo(0, 0);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        window.scrollTo(0, 0);
      }
    },
    exportToExcel() {
      const exportData = this.products.map((product) => ({
        Name: product.name,
        Price: product.purchasing_price,
        Commission: product.commission,
        Stock: product.total_stock,
        Vendor: product.vendor,
        Category: product.category_id,
      }));
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Products");
      XLSX.writeFile(workbook, "products.xlsx");
    },
    async getProducts() {
      this.ensureToken();
      try {
        const response = await fetch(
          "https://ecomerg.za3bot.com/api/view/products",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        const data = await response.json();
        if (data.status === 1 && data.data && data.data.products) {
          this.products = [...this.products, ...data.data.products];
          this.favoriteProducts = this.products
            .filter((p) => p.favorite)
            .map((p) => p.id);
        } else {
          console.error(data.msg || "Failed to fetch products");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
  },
  created() {
    this.fetchVendors(); // Fetch vendors when the component is created
  },
  async mounted() {
    this.ensureToken();
    await this.fetchCategories();
    await this.getProducts();
    await this.fetchProductsByCategory(this.category_id);
  },
};
</script>

<style scoped>
.secPrroductInHome a {
  text-decoration: none;
  font-weight: bold;
}
.DivProduct {
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0px;
}
.DivProduct .productImage {
  width: 100%;
  height: 60%;
}
.DivProduct .productImage img {
  width: 100%;
  height: 100%;
}
.contentProduct {
  padding: 5px;
}
.contentProduct p {
  color: #0c2d58;
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 18px;
}
.contentProduct span {
  font-weight: bold;
  text-align: justify;
  display: block;
  font-size: 14px;
}
.contentProduct small {
  display: block;
  color: #565656;
  text-align: justify;
}
.btnBorderRadius {
  border-radius: 50%;
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activeHeart {
  color: red;
}

.navbar {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}
.nav-item {
  padding: 0 15px;
}
.pagination-button {
  min-width: 40px;
}
.custom-selects {
  appearance: none; /* Remove default browser styling */
  padding: 10px 15px; /* Add padding for better readability */
  font-size: 16px; /* Adjust font size */
  color: #333; /* Default text color */
  background-color: #f8f9fa; /* Light background */
  border: 1px solid #ddd; /* Light border */
  border-radius: 5px; /* Rounded corners */
  outline: none; /* Remove focus outline */
  transition: all 0.3s ease; /* Smooth hover effect */
  cursor: pointer;
}
.custom-select {
  appearance: none; /* Remove default browser styling */
  padding: 10px 15px; /* Add padding for better readability */
  font-size: 16px; /* Adjust font size */
  color: #333; /* Default text color */
  background-color: #f8f9fa; /* Light background */
  border: 1px solid #ddd; /* Light border */
  border-radius: 5px; /* Rounded corners */
  outline: none; /* Remove focus outline */
  transition: all 0.3s ease; /* Smooth hover effect */
  cursor: pointer;
}

.custom-select:hover {
  background-color: #e9ecef; /* Slightly darker background on hover */
  border-color: #aaa; /* Darker border on hover */
}

.custom-select:focus {
  border-color: #007bff; /* Blue border when focused */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Subtle focus shadow */
}

.custom-select option {
  color: #333; /* Text color for options */
  background-color: #fff; /* Background color for options */
}

/* Ensure compatibility with mobile devices */
@media (max-width: 768px) {
  .custom-select {
    width: 100%; /* Make dropdown full-width on smaller screens */
  }
}
</style>
